import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import logo from "../assets/regal_logo.png";

const LogoText = styled.h1`
font-family: 'Akaya Telivigala', cursive;
font-size: ${props => props.theme.fontxxxl};
color: ${props => props.theme.text};
transition: all 0.2s ease;

display: flex;
align-items: center;
// width: 2rem;
height: auto;
cursor: pointer;
// border: 1px solid green;
img {
  // margin-right: 0.5rem;
  max-width: 80px;
  margin-top: 20px;
}

&:hover{
    transform: scale(1.1);
}

@media (max-width: 64em){
font-size: ${props => props.theme.fontxxl};

}
`

const Logo = () => {
  return (
    <LogoText>
        <Link to="/">
        <img src={logo} alt="CodeBucks" />
       
        </Link>
    </LogoText>
  )
}

export default Logo