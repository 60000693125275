import { createGlobalStyle } from "styled-components";
import "@fontsource/akaya-telivigala"
import "@fontsource/sora"
import "@fontsource/montserrat"
import "@fontsource/raleway"
import "@fontsource/twinkle-star"
import "@fontsource/amiri"

const GlobalStyles = createGlobalStyle`

${'' /* 
*{
    outline: 1px solid red !important;
} */}

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Eczar&family=Inknut+Antiqua:wght@300&family=Libre+Baskerville&family=Lora&family=Playfair+Display&family=Source+Serif+Pro&display=swap');
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Cormorant Garamond', serif;
  }

*,*::before,*::after{
    margin: 0;
    padding: 0;
}

body{
    font-family: 'amiri', sans-serif;
    overflow-x: hidden;
}

h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
}
a{
    color: inherit;
    text-decoration:none;
}
`

export default GlobalStyles;