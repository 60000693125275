import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContentSec = styled.div`
	// padding: 60px 0;
	background: transparent;
	// border: 1px solid yellow;
`;

export const ContentRow = styled.div`
	display: flex;


	@media screen and (max-width: 768px) {
		flex-direction: column-reverse;
	}
`;

export const ContentColumn = styled(motion.div)`
	margin-bottom: 5rem;
	// border: 1px solid red;
	flex: 1;
	z-index: 10;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 768px) {
		max-width: 100% !important;
		flex-basis: 100%;
		justify-content: center;
		align-items: center;
	}
`;

export const TextWrapper = styled.div`
	max-width: 540px;
	padding-top: 0;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (max-width: 768px) {
		padding-bottom: 0px;
		> h1,
		p {
			text-align: center;
		}
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	> img {
		width: 300px;
		margin-left: -3px;
	}
`;

export const ContentButton = styled(motion.button)`
	height: 3rem;
	padding: 16px 32px;
	font-weight: 700;
	font-size: 0.8rem;
	line-height: 18px;
	letter-spacing: 1.54px;
	text-transform: uppercase;
	cursor: pointer;
	background: none;
	color: ${({ inverse }) => (inverse ?  '#d4af37' : 'black')};
	border-radius: 4px;
	white-space: nowrap;
	padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
	font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
	outline: none;
	border: 3px solid #f5347f;
	cursor: pointer;
	position: relative;
	overflow: hidden;

	&:before {
		background: linear-gradient(-45deg, #ffcee6, #f5347f);
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: -1;
		transition: all 0.6s ease;
		width: 0%;
		height: 100%;
	}

	&:hover:before {
		width: 200%;
	}

	&:hover {
		color: ${({ inverse }) => (inverse ? 'white' : 'black')};
	}
`;

export const ImgWrapper = styled(motion.div)`
	display: flex;
	justify-content: ${({ imgStart }) => (imgStart ? 'flex-start' : 'flex-end')};
	max-height: 700px;
	justify-content: center;
	position: relative;
	// background-color: #aa0000;
	background: linear-gradient(-45deg, #fed693, #ca7100);
	padding: 4px 0;
	max-height: 40%;
`;

export const TopLine = styled(motion.span)`
	font-size: 0.9rem;
	line-height: 16px;
	font-weight: 550;
	letter-spacing: 1.4px;
	margin-bottom: 0.8rem;

	background-color: #aa0000;
	color: #fff;
	padding: 5px 10px;
	border-radius: 5px;
`;

export const Img = styled(motion.img)`
	padding-right: 0;
	border: 0;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
	object-fit: cover;
	/* height: 400px; */
	max-height: 700px;
	z-index: 1;
`;

export const ContentHeading = styled(motion.h2)`
	margin: 1rem 0 1rem;
	// font-size: clamp(1.3rem, 7vw, 3rem);
	font-size: clamp(2.1rem, 7vw, 3rem);
	line-height: 1.1;
	font-weight: 600;
	color: ${({ inverse }) => (inverse ? 'white' :'black'  )};
	background: -webkit-linear-gradient(135deg, #fed693, #ca7100);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	text-align: center;

	@media screen and (max-width: 768px) {
		text-align: center;
	}
`;

export const Subtitle = styled(motion.p)`
	max-width: 440px;
	margin-top: ${({ mt }) => (mt ? '1.3rem' : '0')};
	margin-bottom: 35px;
	line-height: 24px;
	text-align: justify;
	color: ${({ inverse }) => (inverse ?  'white' : '#6a6a6a')};
	font-size: clamp(1rem, 2vw, 1rem);
`;
