import React from "react";
import InstaFeeds from "./InstaComponent/InstaFeeds";
import background from "./pink.jpg";
import { ins_url } from "../../fresha_url";

const Instagram = () => {
  return (
    <div
      id="instagram"
      style={{
        backgroundImage: `url(${background})`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1 className="instagram-title">Follow us on Instagram</h1>
      <a
        href={ins_url}
        target="_blank"
        style={{
          fontSize: "1.5rem",
          fontWeight: 600,
          marginBottom: "2rem",
          textDecoration: "none",
          color: "#fe036a",
        }}
      >
        #regalnailmanukau
      </a>

      <InstaFeeds
        token="IGQWRPMm5fSEpnbUVwbjZAvQXBqU3g1b1dKNkdQT09NUzNVQ1JnX0ZArbXowT253UDJJN2RHWTdvQk9vSURUdmh4WUdMSUpYZAkE4WkdMUkNZARkVkOEVFbGNPbGw5V3Y0UHRBTlNUaVBJUTFaUjBlZAzFDbFhnLUtKUTgZD"
       
        limit={12}
      />
    </div>
  );
};

export default Instagram;
