// This file contains variables for different themes

export const light = {
    
    // body:"#fff",
    body:"#fff",
    oliu:"#4e3e25",

    text:"#202020", // black shade
    bodyRgba : "255, 255, 255",
    pinkRgba : "243, 173, 184",

    textRgba:"32,32,32",

    carouselColor: '#F3ADB8',
    pink_bold:'#fe036a',
    pink_light:'#758d93',
    fontxs:'0.75em',
    fontsm:'0.875em',
    fontmd:'1em', // 1em = 16px
    fontlg:'1.25em',
    fontxl:'2em',
    fontxxl:'3em',
    fontxxxl:'4em',

    fontButton:'0.875em',


    navHeight: '5rem',
    navHeight1: '10rem',
}


export const dark = {
    
    body:"#202020",
    text: "#fff", // black shade
    bodyRgba :"32,32,32" ,
    textRgba: "255, 255, 255",

    carouselColor: '#EEEDDE',

    fontxs:'0.75em',
    fontsm:'0.875em',
    fontmd:'1em', // 1em = 16px
    fontlg:'1.25em',
    fontxl:'2em',
    fontxxl:'3em',
    fontxxxl:'4em',

    fontButton:'0.875em',


    navHeight: '5rem',
}