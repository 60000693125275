import React, { lazy, Suspense } from "react";
import styled, { ThemeProvider } from "styled-components";
// import Carousel from '../Carousel'
import Button from "../Button";
import { dark, light } from "../../styles/Themes";
import Loading from "../Loading";
import "./about_button.css";
import { fesha_url } from "../../fresha_url";
import background from "./pink.jpg"

const CarouselAbout = lazy(() => import("../Carousel_about"));

const Section = styled.section`
  min-height: 30vh;
  width: 100%;
  background-color: ${(props) => props.theme.oliu};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;
const Container = styled.div`
  width: 75%;
  margin: 0 auto;
  /* background-color: lightblue; */

  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 70em) {
    width: 85%;
  }

  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 80%;
    }
  }
  @media (max-width: 40em) {
    & > *:last-child {
      width: 90%;
    }
  }
`;
const Box = styled.div`
  width: 50%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 40em) {
    min-height: 50vh;
  }
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.pink_bold};
  align-self: center;
  text-align: center;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;
const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.text};
  align-self: center;
  text-align: center;
    width: 80%;
  margin: 1rem auto;
  font-weight: 400;
  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontlg};
  }
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontlg};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontmd};
  }
`;
const SubTextLight = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => `rgba(${props.theme.textRgba},0.6)`};
  align-self: center;
  justify-content: center;
  text-align: center;
  width: 80%;
  margin: 1rem auto;
  font-weight: 400;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  align-self: flex-start;

  @media (max-width: 48em) {
    width: 100%;
    align-self: center;
    text-align: center;

    button {
      margin: 0 auto;
    }
  }
`;

const About = () => {
  return (
    <Section id="about" style={{ 
      // backgroundImage: `url(${background}),`
    
    background: "#F3ADB8"
    }}>
      <Container>
        {/* <Box>
          <Suspense fallback={<Loading />}>
            <CarouselAbout />{" "}
          </Suspense>{" "}
        </Box> */}
        <Box>
          <Title>
            Welcome To <br /> Regal Nail & Beauty
          </Title>
          <SubText>
            Regal Nail Beauty and Spa is an ideal destination for nail and
            beauty services. We offer services such as manicures, pedicures,
            acrylics, eyebrow and lip tattoos, eyelash extensions and even
            waxing. In addition, we are offering relaxing massage shampoo
            services with many different options.
          </SubText>
          <SubTextLight>
            Regal Nails Beauty and Spa has friendly and professional staff who
            are always ready to serve you. Welcome to Regal Nails located at 652
            Great South Road, Manukau City, Auckland.{" "}
          </SubTextLight>
          <ButtonContainer>
            <a href={fesha_url} target="_blank">
              <div class="centered">
                <div class="button_about glass3 clear">
                  BOOK NOW
                  <div class="glass3hover">BOOK NOW</div>
                  <div class="glass3ref"></div>
                  <div class="glass3hi"></div>
                  <div id="hi3" class="glass3hi"></div>
                  <div id="hi3" class="glass3hi"></div>
                </div>
              </div>
            </a>
          </ButtonContainer>
        </Box>
      </Container>
    </Section>
  );
};

export default About;
