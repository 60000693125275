const menu = [
  {
    id: 1,
    title: 'Arcrylic nails with gel ',
    category: 'Extension Nail',
    price: 65,
    img: './images/item-1.jpeg',
    desc: '',
  },
  {
    id: 2,
    title: 'Arcrylic with normal Polish ',
    category: 'Extension Nail',
    price: 50,
    img: './images/item-2.jpeg',
    desc: ``,
  },
  {
    id: 3,
    title: 'Precription Gel Nails ',
    category: 'Extension Nail',
    price: 55,
    img: './images/item-4.jpeg',
    desc: ``,
  },
  {
    id: 4,
    title: 'SNS extension nails',
    category: 'Extension Nail',
    price:70,
    img: './images/item-5.jpeg',
    desc: ``,
  },
  {
    id: 5,
    title: 'SNS on natural nails ',
    category: 'Extension Nail',
    price: 55,
    img: './images/item-6.jpeg',
    desc: ``,
  },
  {
    id: 6,
    title: 'Deluxe Manicure Gel ',
    category: 'Nail care',
    price: 45,
    img: './images/item-7.jpeg',
    desc: ``,
  },
  {
    id: 7,
    title: 'Deluxe Manicure Normal Polish ',
    category: 'Nail care',
    price: 30,
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 8,
    title: 'Deluxe Spa Pedicure Gel ',
    category: 'Nail care',
    price: 55,
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 9,
    title: 'Spa Pedicure Normal Polish',
    category: 'Nail care',
    price: '40',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 10,
    title: 'Deluxe Pedi & Mani Gel',
    category: 'Combo & Extra Treatment ',
    price: '95',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 11,
    title: 'Deluxe Pedi & Mani Normal',
    category: 'Combo & Extra Treatment ',
    price: '65',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 12,
    title: 'Full-set nails art/Design',
    category: 'Combo & Extra Treatment ',
    price: '20-40',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 13,
    title: 'Design per nail',
    category: 'Combo & Extra Treatment ',
    price: '5/each',
    img: './images/item-9.jpeg',
    desc: ``,
  },
];
export default menu;
