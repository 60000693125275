const menu = [
  {
    id: 1,
    title: 'Individual Classis set',
    category: 'Eyelashes extensions',
    price: 70,
    img: './images/item-1.jpeg',
    desc: ``,
  },
  {
    id: 2,
    title: 'Russian Volume Light Set',
    category: 'Eyelashes extensions',
    price: 85,
    img: './images/item-2.jpeg',
    desc: ``,
  },
  {
    id: 3,
    title: 'Medium Volume Set',
    category: 'Eyelashes extensions',
    price: 95,
    img: './images/item-3.jpeg',
    desc: ``,
  },
  {
    id: 4,
    title: 'Mega Volume Set',
    category: 'Eyelashes extensions',
    price: 120,
    img: './images/item-4.jpeg',
    desc: ``,
  },
  {
    id: 5,
    title: 'Hybrid Set',
    category: 'Eyelashes extensions',
    price: 85,
    img: './images/item-5.jpeg',
    desc: ``,
  },
  {
    id: 6,
    title: 'Eyebrow wax',
    category: 'Lash & Brow',
    price: 10,
    img: './images/item-6.jpeg',
    desc: ``,
  },
  {
    id: 7,
    title: 'Eyebrow tint',
    category: 'Lash & Brow',
    price: 15,
    img: './images/item-7.jpeg',
    desc: ``,
  },
  {
    id: 8,
    title: 'Lash tint',
    category: 'Lash & Brow',
    price: 20,
    img: './images/item-8.jpeg',
    desc: ``,
  },
  {
    id: 9,
    title: 'Lips & Chin Wax',
    category: 'Waxing',
    price: 20,
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 10,
    title: 'Full face wax',
    category: 'Waxing',
    price: '45',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 11,
    title: 'Full arms/ Half arms',
    category: 'Waxing',
    price: '35/25',
    img: './images/item-9.jpeg',
    desc: ``,
  },
  {
    id: 12,
    title: 'Full legs/ Half legs',
    category: 'Waxing',
    price: '50/35',
    img: './images/item-9.jpeg',
    desc: ``,
  },
 
  
];
export default menu;
