import React from "react";
import MessengerCustomerChat from "react-messenger-customer-chat";
const Fbchat = () => {
  return (
    <div>
      {/* <MessengerCustomerChat pageId="106279360798692" appId="910231926724497" /> */}
      <MessengerCustomerChat pageId="114936831622628" appId="222940330764980" themeColor={'#f5347f'} minimized={'true'}/>

    </div>
  );
};

export default Fbchat;
