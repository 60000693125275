import React, { lazy, Suspense } from "react";
import styled from "styled-components";
// import Banner from './Banner'
import Logo from "./Logo";

import Facebook from "../Icons/Facebook";
import Instagram from "../Icons/Instagram";
import Twitter from "../Icons/Twitter";
import LinkedIn from "../Icons/LinkedIn";
import Loading from "./Loading";
import background from "./sections/pink.jpg";
import { ins_url } from "../fresha_url";


const Banner = lazy(() => import("./Banner"));

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.body};
  // background: red;
  position: relative;
  color: ${(props) => props.theme.text};

  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
`;

const Container = styled.div`
  width: 75%;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${(props) => props.theme.fontlg};

  border-bottom: 1px solid ${(props) => props.theme.text};

  @media (max-width: 48em) {
    width: 90%;

    h1 {
      font-size: ${(props) => props.theme.fontxxxl};
    }
  }
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: 1px solid red;

  @media (max-width: 48em) {
    width: 100%;
  }
`;

const IconList = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem auto;

  & > * {
    padding-right: 0.5rem;
    transition: all 0.2s ease;

    &:hover {
      transform: scale(1.2);
    }
  }
`;
const MenuItems = styled.ul`
  list-style: none;
  width: 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1rem;

  @media (max-width: 48em) {
    display: none;
  }
`;
const Item = styled.li`
  width: fit-content;
  cursor: pointer;

  &::after {
    content: " ";
    display: block;
    width: 0%;
    height: 2px;
    background: ${(props) => props.theme.text};
    transition: width 0.3s ease;
  }
  &:hover::after {
    width: 100%;
  }
`;

const Bottom = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${(props) => props.theme.fontlg};

  a {
    text-decoration: underline;
  }
  @media (max-width: 48em) {
    flex-direction: column;
    width: 100%;
    font-size: ${(props) => props.theme.fontmd};

    span {
      margin-bottom: 1rem;
    }
  }
`;

const Title_contact = styled.h1`
  font-size: ${(props) => props.theme.fontxl};

  background: -webkit-linear-gradient(135deg, #fe036a, #f5347f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-top: 1rem;
  z-index: 10;
  text-transform: capitalize;
  margin: 0 auto;
// border: 1px solid red;

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};
    text-align: center;
    width: 40%;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
    padding-top: 2rem;

    width: 100%;
  }
`;
const Footer = () => {
  const scrollTo = (id) => {
    let element = document.getElementById(id);

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <Section 
    id="contactus"
    style={{
      background:  ` url(${background}) center`,
  
  // background: "#F3ADB8",
  }}
    
    >
      <Suspense fallback={<Loading />}>
        <Banner />{" "}
      </Suspense>
      
      <Title_contact>Call us:  <a href='tel: +6492638776' style={{color: "#fff", textDecoration:"none"}}>092 638 776</a></Title_contact>
      <Title_contact>
      <a
        href={ins_url}
        target="_blank"
        style={{
          fontSize: "1.5rem",
          fontWeight: 600,
          marginBottom: "0rem",
          textDecoration: "none",
          color: "#fe036a",
        }}
      >
        #regalnailmanukau
      </a>
      </Title_contact>
      <Container>
        <Left>
          <Logo />
          <IconList>
            <a
              href="https://www.facebook.com/regalnailbeauty"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="facebook"
            >
              <Facebook />
            </a>
            <a
              href="https://www.instagram.com/regalnailmanukau/?igshid=OGQ5ZDc2ODk2ZA%3D%3D&fbclid=IwAR0OrZUx7X8JEV1VIUgcPiDGYlaH_iTKs01Ing5m9rI7jF9HwwXY-8D7uJs"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="instagram"
            >
              <Instagram />
            </a>
          </IconList>
        </Left>
        <MenuItems>
          <Item onClick={() => scrollTo("home")}>Home</Item>
          <Item onClick={() => scrollTo("about")}>About</Item>
          <Item onClick={() => scrollTo("nails")}>Nails Services</Item>
          <Item onClick={() => scrollTo("beauty")}>Beauty Services</Item>
          <Item onClick={() => scrollTo("team")}>Facebook</Item>
          <Item onClick={() => scrollTo("faq")}>Instagram</Item>
        </MenuItems>
      </Container>
      <Bottom>
        <span>&copy; 2023 Regal Nails and Beauty. All rights reserved.</span>
        <span>
          Made with &#10084; by{" "}
          <a
            href="https://miraelite.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mira Elite
          </a>
        </span>
      </Bottom>
    </Section>
  );
};

export default Footer;
